import { tw } from '@/utils/tw';

const size = ['w-32', 'h-32', 'md:w-40', 'md:h-40'];

export default tw.theme({
  slots: {
    base: ['shape-sticker', 'm-grid-1/2', 'bg-red', 'float-right', '-rotate-[9deg]', 'not-prose', ...size],
    quote: [
      'm-0',
      'p-[15%]',
      'w-full',
      'h-full',
      'flex',
      'box-border',
      'justify-center',
      'items-center',
      'text-center',
      'text-quote-sm',
      'text-white',
      'hyphens-auto',

      'md:text-quote-md',
    ],
    object: size,
  },
});
